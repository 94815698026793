<template>
  <div>
    <div class="rsnButton">
      <div v-if="!(isLook||isModify)">
        <el-button type="primary" @click="addGoods()" v-if="!isProblemBill" size="small">新增</el-button>
        <el-button type="danger" v-if="!isReissue&&!isProblemBill" :disabled="this.multipleSelection.length===0"
                   @click="deleteSomeGoods()" size="small">
          删除
        </el-button>
      </div>
      <span class="msg_div">
        <span class="title">总件数：<span class="num">{{ AllPackage }}</span></span>
        <span class="title">总毛重：<span class="num">{{ AllWeight }}</span></span>
        <span class="title">总体积：<span class="num">{{ AllVolume }}</span></span>
      </span>
    </div>
    <div class="the_table">
      <el-form :model="form" :rules="rules" ref="form">
        <el-table
            ref="multipleTable"
            border
            :data="form.goodsList"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            :header-cell-style="{background: '#d4d9e1',color: '#606278',}"
            :cell-style="{ 'text-align': 'center', padding: '0px' }"
            :max-height="500"
        >
          <slot name="first" v-if="!isLook">
            <el-table-column fixed type="selection" width="55" align="center"></el-table-column>
          </slot>
          <el-table-column
              type="index"
              width="50">
          </el-table-column>
          <el-table-column
              align="left"
              v-for="(item,index) in goodsTable"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width">
            <template slot="header">
              <span style="margin-left:10px">{{ item.label }}
              <span v-if="['billno','ctnno','cargoname','wraptype','mark'].includes(item.prop)" class="header_sync_btn"
                    @click="headerSync(item.prop)">同步<i class="el-icon-download"></i></span>
              </span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                  :prop="'goodsList.'+scope.$index+'.'+item.prop"
                  :rules="rules[item.prop]">
                <el-select
                    :disabled="isLook"
                    v-if="item.prop === 'ctntype'"
                    v-enterNextInput
                    v-model.trim="scope.row[item.prop]"
                    filterable
                    default-first-option
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in ctnTypes"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value">
                  </el-option>
                </el-select>
                <el-select
                    :disabled="isLook||isProblemBill"
                    v-else-if="item.prop === 'boxmark'"
                    v-enterNextInput
                    v-model="scope.row[item.prop]"
                    filterable
                    default-first-option
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in boxesmark"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value">
                  </el-option>
                </el-select>
                <el-select
                    :disabled="isLook"
                    default-first-option
                    v-else-if="item.prop === 'wraptype'"
                    v-enterNextInput
                    v-model.trim="scope.row[item.prop]"
                    filterable
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in wraptypes"
                      :key="item1.value + ' | ' + item1.label"
                      :label="item1.value + ' | ' + item1.label"
                      :value="item1.value + ' | ' + item1.label">
                  </el-option>
                </el-select>
                <el-select
                    :disabled="isLook||isProblemBill"
                    v-else-if="item.prop === 'temperatureUnit'"
                    v-enterNextInput
                    v-model.trim="scope.row[item.prop]"
                    filterable
                    clearable
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in temperatureUnits"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value">
                  </el-option>
                </el-select>
                <el-switch
                    :disabled="isLook||isProblemBill"
                    v-else-if="item.prop === 'bstatus'"
                    v-model="scope.row[item.prop]"
                    active-color="lightgreen"
                    inactive-color="grey"
                    active-value="E"
                    inactive-value="F">
                </el-switch>
                <el-input
                    v-else-if="item.prop==='cargoname'"
                    :autosize="{ minRows: 4, maxRows: 6}"
                    type="textarea"
                    :disabled="isLook||(item.prop==='cargoname')&&isModify||isProblemBill"
                    @input="inputFilter($event,scope.row, item.prop)"
                    resize="none"
                    maxlength="256"
                    show-word-limit
                    v-model="scope.row[item.prop]">
                </el-input>
                <el-input
                    v-else-if="item.prop==='mark'"
                    :autosize="{ minRows: 4, maxRows: 6}"
                    type="textarea"
                    :disabled="isLook||(item.prop==='mark')&&isModify||isProblemBill"
                    @input="inputFilter($event,scope.row, item.prop)"
                    resize="none"
                    v-model="scope.row[item.prop]">
                </el-input>
                <el-input
                    v-else
                    :disabled="isLook||(item.prop==='billno'||item.prop==='ctnno')&&isModify||(item.prop==='billno'&&isReissue)||isProblemBill"
                    @input="inputFilter($event,scope.row, item.prop)"
                    v-model="scope.row[item.prop]">
                </el-input>
              </el-form-item>
            </template>

          </el-table-column>
          <slot name="todo" v-if="!(isLook||isModify)">
            <el-table-column fixed="right" label="操作" width="160" align="center">
              <template slot-scope="scope">
                <div class="btn_div">
                  <span class="copy_span" v-if="!isProblemBill" @click="copyGoods(scope.row)">复制</span>
                  <span class="delete_span" v-if="!isReissue&&!isProblemBill"
                        @click="showDeleteDialog(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </el-table>
      </el-form>
    </div>
    <el-dialog title="确认要删除以下货物明细吗？" :visible.sync="deleteDialogVisible" width="30%" append-to-body center
               @close="deleteGoodsList = []">
      <el-table :data="deleteGoodsList" border stripe style="width: 100%" :header-cell-style="{
            background: '#d4d9e1',
            color: '#606278',
            'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column prop="billno" label="提单号" width="180"></el-table-column>
        <el-table-column prop="ctnno" label="箱号" width="180"></el-table-column>
        <el-table-column prop="sealno" label="封号"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false;deleteGoodsList = []">取 消</el-button>
        <el-button type="primary" @click="deleteGoods()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ctnTypes,
  boxesmark,
  wraptypes, goodsType,
} from "@/data/manifestInformation";
import {deleteById, querySelectGoods, saveGoods, updategoods} from "@/api/shanghaiManifest";
import {
  setFloatFormat,
  setNumberFormat, setRightDgclassForrmat,
  setRightEmailForrmat,
  setRightForrmat, setRightForrmat2,
  setRightForrmatPlus
} from "@/utils/validate";
import {math} from "@/utils/math";

export default {
  name: 'ManifestGoodsTable',
  props: {
    item: {
      type: Array,
      default: () => []
    },
    selectGoodsType: {
      type: String,
      default: 'S-普通货物'
    },
    isLook: {
      type: Boolean,
      default: false
    },
    isModify: {//已发送的舱单进行修改
      type: Boolean,
      default: false,
    },
    isReissue: {//已删除的舱单进行重发
      type: Boolean,
      default: false,
    },
    isProblemBill: {//已发送超时没回执的舱单
      type: Boolean,
      default: false,
    }
  },
  watch: {
    item(newVal, oldVal) {
      this.form.goodsList = newVal

    },
    selectGoodsType(newVal) {
      console.log(newVal);
      this.$refs.form.clearValidate();
      const isDanger = newVal === this.goodsType[2].value;
      const isCold = newVal === this.goodsType[1].value;
      //危险品
      this.rules.dguncode.required = isDanger;
      this.rules.dgclass.required = isDanger;
      this.rules.hazmat.required = isDanger;
      this.rules.hazmatnum.required = isDanger;
      this.rules.email.required = isDanger;
      //冻品
      this.rules.temperature.required = isCold;
      this.rules.temperatureUnit.required = isCold;

    }
  },
  computed: {
    AllPackage() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.packno) {
          temp = math.add(temp, item.packno)
          // temp += parseFloat(item.packno);
        }
      })
      return temp;
    },
    AllWeight() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.weight) {
          // temp += parseFloat(item.weight);
          temp = math.add(temp, item.weight)
        }
      })
      return temp;
    },
    AllVolume() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.volume) {
          temp = math.add(temp, item.volume)
          // temp += parseFloat(item.volume);
        }
      })
      return temp;
    }
  },
  data() {
    const validateBillNo = (rule, value, callback) => {
      if (!value || value.length < 6 || value.length > 30) {
        callback(new Error('提单号长度在6-30字符'));
      } else if (!(/(^[A-Z0-9]+)$/.test(value))) {
        callback(new Error('只能大写字母和数字'));
      } else {
        callback();
      }
    };
    const validateCtnNo = (rule, value, callback) => {
      if (!value || value.length !== 11) {
        callback(new Error('箱号为11位字符'));
      } else if (!(/(^[A-Z]{4}[0-9]{7})$/.test(value))) {
        callback(new Error('只能4位字母+7位数字'));
      } else {
        callback();
      }
    };
    const validateSealNo = (rule, value, callback) => {
      if (!value || value.length < 3 || value.length > 28) {
        callback(new Error('封号长度在28字符以内'));
      } else if (!(/(^[A-Z0-9\-]+)$/.test(value))) {
        callback(new Error('只能是大写字母和数字'));
      } else {
        callback();
      }
    };
    const validateCargoname = (rule, value, callback) => {
      if (!value || value.length > 256) {
        callback(new Error('品名长度在256字符以内'));
      } else if (!(/(^[A-Za-z0-9&:;,./@$%()#'" \-]+)$/.test(value))) {
        callback(new Error('不要输入中文和全角符号'));
      } else {
        callback();
      }
    };
    const validateWaryType = (rule, value, callback) => {
      if (!(/(^[A-Z]{2} [|] [A-Za-z \-.']+)$/.test(value))) {
        callback(new Error('格式：代码 | 名称'));
      } else {
        callback();
      }
    };
    const validateCtnType = (rule, value, callback) => {
      if (!(/(^[0-9]{2}[A-Z]{2})$/.test(value))) {
        callback(new Error('2位数字+代码'));
      } else {
        callback();
      }
    };
    const validateMark = (rule, value, callback) => {
      if (!(/(^[A-Za-z0-9&:;,./@%()#'" \-]+)$/.test(value))) {
        callback(new Error('不要输入中文和全角符号'));
      } else {
        callback();
      }
    };
    const validateDguncode = (rule, value, callback) => {
      if (this.rules.dguncode.required && !(/(^[0-9]{4})$/.test(value))) {
        callback(new Error('只能是4位数字'));
      } else {
        callback();
      }
    };
    const validateDgclass = (rule, value, callback) => {
      if (this.rules.dgclass.required && !(/(^([1-9])(\.\d)?(([+][1-9])(\.\d)?)?)$/.test(value))) {
        callback(new Error('1-9.9,含一位小数'));
      } else {
        callback();
      }
    };
    const validateHazmat = (rule, value, callback) => {
      if (this.rules.hazmat.required && (!value || value.length === 0 || !(/(^[A-Za-z ]+)$/.test(value)))) {
        callback(new Error('只能英文字母和空格'));
      } else {
        callback();
      }
    };
    const validateHazmatnum = (rule, value, callback) => {
      if (this.rules.hazmatnum.required && !(/(^[0-9,./()\- ]+)$/.test(value))) {
        callback(new Error('只能输入数字，半角符号（含空格）'));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (this.rules.hazmatnum.required && !(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value))) {
        callback(new Error('请输入有效邮箱'));
      } else {
        callback();
      }
    };
    const validateTemperature = (rule, value, callback) => {
      if (this.rules.temperature.required && !(/(^[-](?:\d{1,3}|(?=.{3,4}$)\d+\.\d{1,2})$)/.test(value))) {
        callback(new Error('0以下3位数字（含小数点）'));
      } else {
        callback();
      }
    };
    const validateWeight = (rule, value, callback) => {
      if (!(/^\d+(\.\d{1,3})?$/.test(value))) {
        callback(new Error('重量最多3位小数'));
      } else if (value > 99999.99) {
        callback(new Error('不能超过99999.99'));
      } else {
        callback();
      }
    };
    const validatePackno = (rule, value, callback) => {
      if (!(/(^[1-9]\d*$)/.test(value))) {
        callback(new Error('大于0的整数'));
      } else if (value > 99999) {
        callback(new Error('不能超过99999'));
      } else {
        callback();
      }
    };
    const validateVolume = (rule, value, callback) => {
      if (!(/^\d+(\.\d{1,3})?$/.test(value))) {
        callback(new Error('体积最多3位小数'));
      } else if (value > 99.999) {
        callback(new Error('不能超过99.999'));
      } else {
        callback();
      }
    };
    return {
      goodsType,
      wraptypes,
      boxesmark,
      ctnTypes,
      temperatureUnits: [
        {
          value: 'C',
          label: '摄氏度/℃'
        },
        {
          value: 'F',
          label: '华氏度/℉'
        }
      ],
      goodsTable: this.RESETTABLE.goodsDetail,
      deleteDialogVisible: false, //删除货物弹窗
      multipleSelection: [],
      deleteGoodsList: [],
      rules: {
        billno: [
          {required: true, message: "请输入提单号", trigger: "change"},
          {validator: validateBillNo, trigger: 'blur'},
        ],
        ctnno: [{required: true, message: '请输入箱号', trigger: "change"},
          {validator: validateCtnNo, trigger: 'blur'},],
        sealno: [{required: true, message: '请输入封号', trigger: "change"},
          {validator: validateSealNo, trigger: 'blur'},],
        ctntype: [{required: true, message: '请选择箱型', trigger: "blur"},
          {validator: validateCtnType, trigger: 'blur'}],
        boxmark: {required: true, message: '请选择货主箱标记', trigger: "blur"},
        cargoname: [{required: true, message: '请输入英文品名', trigger: "blur"},
          {validator: validateCargoname, trigger: 'blur'},],
        wraptype: [{required: true, message: '请选择包装单位', trigger: "blur"},
          {validator: validateWaryType, trigger: 'blur'}],
        packno: [{required: true, message: '件数1~99999', trigger: "blur"},
          {validator: validatePackno, trigger: 'blur'}],
        weight: [{required: true, message: '请输入重量', trigger: "blur"},
          {validator: validateWeight, trigger: 'blur'}],
        volume: [{required: true, message: '请输入体积', trigger: "blur"},
          {validator: validateVolume, trigger: 'blur'}],
        mark: [{required: true, message: '请输入唛头', trigger: "blur"},
          {validator: validateMark, trigger: 'blur'},],
        dguncode: [{required: false, message: '请输入危险品代码', trigger: "blur"},
          {validator: validateDguncode, trigger: 'blur'}],
        dgclass: [{required: false, message: '请输入危险品类型', trigger: "blur"},
          {validator: validateDgclass, trigger: 'blur'}],
        hazmat: [{required: false, message: '请输入危险品联系人', trigger: "blur"},
          {validator: validateHazmat, trigger: 'blur'}],
        hazmatnum: [{required: false, message: '请输入危险品联系电话', trigger: "blur"},
          {validator: validateHazmatnum, trigger: 'blur'}],
        email: [{required: false, message: '请输入危险品联系邮箱', trigger: "blur"},
          {validator: validateEmail, trigger: 'blur'}],
        temperature: [{required: false, message: '请输入冻品温度', trigger: "blur"},
          {validator: validateTemperature, trigger: 'blur'}],
        temperatureUnit: {required: false, message: '请选择温度单位', trigger: "blur"},
      },
      form: {
        goodsList: [],//货物列表
      },
      goodsItem: {
        billno: '',
        ctnno: '',
        sealno: '',
        ctntype: '',
        boxmark: 'N-承运人箱 COC',
        cargoname: '',
        wraptype: '',
        packno: '',
        volume: '',
        weight: '',
        mark: 'N/M',
        dguncode: '',
        dgclass: '',
        hazmat: '',
        hazmatnum: '',
        email: '',
        fax: '',
        bstatus: '',
        isHazardous: 0,
        uuid: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    inputFilter(value, item, key) {
      if (!value) {
        return;
      }
      if (key === 'packno' || key === 'hazmatnum') {
        item[key] = setNumberFormat(value);
      } else if (key === 'billno' || key === 'ctnno') {
        item[key] = setRightForrmat(value);
      } else if (key === 'weight' || key === 'volume') {
        item[key] = setFloatFormat(value);
      } else if (key === 'sealno') {
        item[key] = setRightForrmat2(value);
      } else if (key === 'email') {
        item[key] = setRightEmailForrmat(value);
      } else if (key === 'dgclass') {
        item[key] = setRightDgclassForrmat(value);
      } else if (value) {
        item[key] = setRightForrmatPlus(value);
      }
    },
    addGoods() {
      let item = JSON.parse(JSON.stringify(this.goodsItem));
      item.index = this.form.goodsList.length + 1;
      if (this.isReissue) {
        item.billno = this.form.goodsList[0].billno;
      }
      this.form.goodsList.push(item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteSomeGoods() {
      //删除一堆货物
      this.deleteGoodsList = [];
      if (this.multipleSelection.length !== 0) {
        this.deleteDialogVisible = true;
        this.multipleSelection.forEach((item) => {
          this.deleteGoodsList.push(item);
        });
      } else {
        this.$message({
          message: "请选择要删除的货物！",
          type: "error",
        });
      }
    },
    deleteGoods() {
      //删除货物
      this.deleteGoodsList.forEach((item) => {
        this.form.goodsList = this.form.goodsList.filter(x =>
            x.index !== item.index
        )
      })
      this.deleteDialogVisible = false;
      this.deleteGoodsList = []; //删完重新赋值
      this.multipleSelection = [];
      this.$emit("itemsDeleted", this.form.goodsList)
    },
    showDeleteDialog(item) {
      this.deleteGoodsList.push(item)
      this.deleteDialogVisible = true;
    },
    save(item) {
      // 遍历判断字段是否为空
      for (let key in this.informarionArr) {
        if (!item[key]) {
          this.$message.error(this.informarionArr[key]);
          return;
        }
      }
      if (item.id) {
        updategoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "修改信息成功",
              type: "success",
            });
          }
        });
      } else {
        saveGoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "新增货物成功",
              type: "success",
            });
          }
        });
      }
      this.$forceUpdate();
    },
    copyGoods(item) {
      let good = JSON.parse(JSON.stringify(item));
      good.index = this.form.goodsList.length + 1;
      good.id = null;
      good.billno = '';
      good.ctnno = '';
      good.sealno = '';
      good.packno = '';
      good.volume = '';
      good.weight = '';
      this.form.goodsList.push(good);
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.form.goodsList.length === 0) {
          reject('请添加货物明细')
        }
        if (this.selectGoodsType !== this.goodsType[2].value) {
          const temp = this.form.goodsList.filter(x => x.dguncode || x.dgclass
              || x.hazmat || x.hazmatnum || x.email || x.fax)
          if (temp.length > 0) {
            reject('非危险品请不要填写危险品相关信息！')
          }
        }
        if (this.selectGoodsType !== this.goodsType[1].value) {
          const temp = this.form.goodsList.filter(x => x.temperature || x.temperatureUnit)
          if (temp.length > 0) {
            reject('非冷冻品请不要填写温度相关信息！')
          }
        }
        //货物列表里有相同提单号箱号重复
        //过滤重复的提单号，检查对应的箱号是否重复
        const unique_billNo = this.form.goodsList.filter((obj, index) => this.form.goodsList.findIndex((item) => item.billno === obj.billno && item.ctnno === obj.ctnno) === index);
        // console.log(unique_billNo)
        if (unique_billNo.length !== this.form.goodsList.length) {
          reject('货物列表里有重复的提单号箱号！')
        }
        //检查不同的箱号使用了相同的封号
        const unique_bill_ctnNo = this.form.goodsList.filter((obj, index) => this.form.goodsList.findIndex((item) => item.sealno === obj.sealno && item.ctnno === obj.ctnno) === index);
        const unique_sealNo = [...new Set(unique_bill_ctnNo.map(item => item.sealno))];
        const unique_ctnNo = [...new Set(unique_bill_ctnNo.map(item => item.ctnno))];
        // console.log(unique_bill_ctnNo)
        // console.log(unique_sealNo)
        // console.log(unique_ctnNo)
        if (unique_bill_ctnNo.length !== unique_ctnNo.length || unique_bill_ctnNo.length !== unique_sealNo.length) {
          reject('不同的箱号不可使用重复的封号！')
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve();
          } else {
            reject('请检查货物明细必填项')
          }
        })
      })
    },
    //将第一个提单号或箱封号复制到下面没有填提单号，箱封号的列表中
    headerSync(type) {
      if (this.form.goodsList.length > 1) {
        const first_good = this.form.goodsList[0];
        for (let i = 1; i < this.form.goodsList.length; i++) {
          if (type === 'billno') {
            if (!this.form.goodsList[i].billno) {
              this.form.goodsList[i].billno = first_good.billno;
            }
          } else if (type === 'ctnno') {
            if (!this.form.goodsList[i].ctnno) {
              this.form.goodsList[i].ctnno = first_good.ctnno;
              this.form.goodsList[i].sealno = first_good.sealno;
              this.form.goodsList[i].ctntype = first_good.ctntype;
            }
          } else if (type === 'cargoname') {
            if (!this.form.goodsList[i].cargoname) {
              this.form.goodsList[i].cargoname = first_good.cargoname;
            }
          } else if (type === 'wraptype') {
            if (!this.form.goodsList[i].wraptype) {
              this.form.goodsList[i].wraptype = first_good.wraptype;
            }
          } else if (type === 'mark') {
            if (!this.form.goodsList[i].mark) {
              this.form.goodsList[i].mark = first_good.mark;
            }
          }
        }
      }
    },
  }
}

</script>


<style scoped lang="stylus">

.rsnButton {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .msg_div {
    .title {
      font-size: 16px;
    }

    .num {
      color: var(--RED-C11C20);
      font-size: 22px;
      margin-right: 1rem;
    }
  }
}

.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy_span, .update_span, .delete_span {
  cursor: pointer;
  margin: 0 1rem;
}

.delete_span {
  color: red;
}

.update_span {
  color: blue;
}

.copy_span {
  color: green;
}

.the_table {
  width: 100%;
  margin-top: 1rem;

  /deep/ .el-table .el-table__cell {
    padding: 0 !important;
  }

  /deep/ .el-input__inner {
    border-color: white;
    border-radius: 0;
    padding: 0 6px;
    min-height: 86px;
    font-family: 'Mono-regular' !important;
  }

  /deep/ .el-input__inner:focus {
    border-color: blue;
    border-radius: 0;
  }

  /deep/ .el-input__inner:hover {
    border-color: blue;
    border-radius: 0;
  }

  /deep/ .el-textarea__inner {
    border-color: white;
    border-radius: 0;
    padding: 0 6px;
    font-family: 'Mono-regular' !important;
  }

  /deep/ .el-textarea__inner:focus {
    border-color: blue;
    border-radius: 0;
  }

  /deep/ .el-textarea__inner:hover {
    border-color: blue;
    border-radius: 0;
  }

  .el-form-item {
    margin: 0 !important;
  }

  /deep/ .el-form-item__error {
    top: 65%;
    left: 0;
    padding: 0;
    line-height: 1.5;
    pointer-events: none;
  }

  /deep/ .el-input.is-disabled .el-input__inner {
    color: var(--BLACK-333) !important;
  }

  /deep/ .el-textarea.is-disabled .el-textarea__inner {
    color: var(--BLACK-333) !important;
  }
}

/deep/ .el-table td.el-table__cell div {
  height: 100%;
}

/deep/ .el-table .cell {
  padding: 0 !important;
}

.header_sync_btn {
  margin-left: 1rem;
  cursor: pointer;
  color: blue;
}
</style>
